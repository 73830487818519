import './App.css';

import {Container, Box} from '@mui/material';
import {SocialIcon} from 'react-social-icons';

import logo_black from './assets/logo_black_transparent.png';
import logo_white from './assets/logo_white_transparent.png';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <div>
            <img src={logo_black} className='App-logo' alt='logo' />
            {/* <img src={logo_white} className='App-logo' alt='logo' /> */}
        </div>
            <Box sx={{ fontFamily: 'UnicaOne', fontSize: 88, color:"black"}}>eigenray</Box>
        
        <Container className='Text-anim' sx={{ align:"center", fontFamily: 'Consolas', fontSize: 24, color:"white"}}>
            <p>
At eigenray, we specialize in providing top-tier graphics software development consultancy services to leading companies in the industry. Our expertise lies in the exciting and rapidly evolving fields of computer graphics, ray tracing, and GPU compute optimizations.
</p>
            <p>
Computer graphics is at the heart of creating captivating visual experiences, and we are dedicated to pushing the boundaries of what is possible in this realm. Our team of skilled and experienced professionals combines their technical prowess with a deep understanding of artistic principles to deliver exceptional results. Whether it's designing realistic virtual environments, crafting stunning visual effects, or developing interactive simulations, we have the expertise to bring your vision to life.
</p>            <p>
Ray tracing, a rendering technique that simulates the behavior of light, has revolutionized the way we perceive computer-generated imagery. We have honed our skills in ray tracing algorithms, optimizing performance and enhancing realism in rendering pipelines. Our team's in-depth knowledge of state-of-the-art ray tracing technologies allows us to deliver unparalleled visual quality and efficiency.
</p>            <p>
Harnessing the power of GPU compute optimizations is another key aspect of our work. We understand the immense potential of leveraging the parallel processing capabilities of modern GPUs to accelerate computationally intensive tasks. Through careful analysis and meticulous optimization techniques, we can unlock the full potential of your hardware, maximizing performance and efficiency in your applications.
</p>            <p>
As a consultancy, we take pride in collaborating closely with our clients. We believe that understanding your unique goals and challenges is crucial to delivering tailored solutions. By working together, we can devise strategies, optimize existing pipelines, and develop cutting-edge software that not only meets your requirements but also exceeds your expectations.
</p>            <p>
Partnering with eigenray means gaining access to a team of industry-leading experts who are passionate about the intersection of art and technology. We are committed to staying at the forefront of advancements in computer graphics, ray tracing, and GPU compute optimizations. Our dedication to innovation, combined with our strong problem-solving skills, ensures that we provide you with the best solutions to drive your success.
</p>            <p>
Join us at eigenray, and let's embark on a journey of pushing the boundaries of visual computing together. Contact us today to discuss how we can elevate your projects and drive your company's success to new heights.
</p>
</Container>
    <Box>
        <SocialIcon bgColor="white" url='https://github.com/eigenray-software'/>
        <SocialIcon url='https://linkedin.com/company/eigenray'/>
        <SocialIcon url='https://twitter.com/eigenraysoft'/>
        <SocialIcon url='https://instagram.com/eigenray'/>
    </Box>
    </header>
    </div>
  );
}

export default App;
